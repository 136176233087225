
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    name: 'App',
    components: {
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const comp = reactive({
            year: 2022,
        });

        onMounted(() => {
            // Mounted
            if(store.state.id_member>0) {
                console.info("AA");
            } else {
                if(route.path!="/Login") {
                    router.push({path: "/Login"});
                }
            }
        });

        return {comp};
    }
}
