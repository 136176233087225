import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router/index.js'

export default createStore({
    state: {
        id_member: 0,
        userid: "",
        pagesize: 50,
        menu: 1,
        authCode: "",
        status: [
            {value: "접수 완료", label:"접수 완료"},
            {value: "DNA Prep", label:"DNA Prep"},
            {value: "Library Prep", label:"Library Prep"},
            {value: "Exome Capture", label:"Exome Capture"},
            {value: "Sequencing", label:"Sequencing"},
            {value: "Data analysis", label:"Data analysis"},
            {value: "QC Fail", label:"QC Fail"},
            {value: "검체 재요청", label:"검체 재요청"},
        ],
        report_status : [
            {value: "검체 재요청", label:"검체 재요청"},
            {value: "Report generation", label:"Report generation"},
            {value: "Report complete", label:"Report complete"},
        ],
        service_status : [
            {value: "", label:"미입력"},
            {value: "S", label:"Service"},
            {value: "R", label:"Research"},
        ],
        gender : [
            {value: "", label:"미입력"},
            {value: "M", label:"남성"},
            {value: "F", label:"여성"},
        ]
    },
    mutations: {
        setMenu(state, value) {
            state.menu = value;
        },
        setUserID(state, id) {
            state.userid = id;
        },
        setPagesize(state, value) {
            state.pagesize = value;
        },
        ajaxCheck(state, data) {
            if(data.err==999) {
                router.push("/Login")
            }
        }
    },
    getters: {
        checkAuth(state) {
            let check = false;
            if("ella@genoplan.com"==state.userid) {
                check = true;
            }
            if("lucy@genoplan.com"==state.userid) {
                check = true;
            }
            if("helena@genoplan.com"==state.userid) {
                check = true;
            }
            if("ecs_lims"==state.userid) {
                check = true;
            }
            
            return check;                
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [createPersistedState()]
})
